import {
    GoAButton,
    GoAButtonGroup,
    GoACallout,
    GoACalloutType,
    GoACircularProgress,
    GoADropdown,
    GoADropdownItem,
    GoAIcon,
    GoAIconButton,
    GoAModal,
    GoASpacer,
    GoATextArea
} from "@abgov/react-components";
import * as yup from "yup"
import './userDetails.scss'
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ApiService from "../../../services/ApiService";
import { InferType } from "yup";
import { CustomFmF } from "../../../components/datePicker/datePicker";
import { Toast } from "../../../components/toast/toast";
import { FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material";
import { useFormik } from "formik";
import { useAuth } from "react-oidc-context";
//import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
//import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
//import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { wdpUtil } from "../../../common/utils/util";
import { tz } from "../../../common/constants";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault(tz);

const adminAreas = [
    "Calgary",
    "Edmonton",
    "Edson",
    "Fort McMurray",
    "Grande Prairie",
    "High Level",
    "Lac La Biche",
    "Peace River",
    "Rocky Mtn House",
    "Slave Lake",
    "Whitecourt"
];

const emailRegex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
const displayNameRegex = /^([a-zA-Z0-9]){1,15}$/;
const getUserSchema = (token: string) => {
    const userSchema = yup.object().shape({
        id: yup.number(),
        userId: yup.string(),
        createdByUserId: yup.number().nullable(),
        createdOn: yup.date().nullable(),
        createdByEmail: yup.string().nullable(),
        createdByDisplayName: yup.string().nullable(),
        updatedOn: yup.date().nullable(),
        updatedByEmail: yup.string().nullable(),
        updatedByDisplayName: yup.string().nullable(),
        updatedByUserId: yup.number().nullable(),
        //createdTimestamp: yup.number(),
        firstName: yup.string().required("Cannot be empty. Enter first name."),
        lastName: yup.string().required("Cannot be empty. Enter last name."),
        emailUnique: yup.bool(), // this is to work as state variable to maintain the uniqueness of the entered email
        displayNameUnique: yup.bool(), // this is to work as state variable to maintain the uniqueness of the entered/suggested displayName
        email: yup.string().email("The entered value is not a valid email")
            .matches(emailRegex, "The entered value is not a valid email")
            .test(
                'is-duplicate-email',
                'An account with this email already exists. Use another email address.',
                (value, context) => {
                    const isUnique = (context?.from || [{}])[0]?.value?.emailUnique;
                    return isUnique;
                }
            )
            .required("Cannot be empty. Enter email address."),
        phone: yup.string(),
        middleName: yup.string(),
        displayName: yup.string()
            .required("Cannot be empty. Enter a Display name which contains only letters and numbers, maximum 15 characters.")
            .test(
                'length-and-non-alphanumeric',
                'Display name can contain only letters and numbers, maximum 15 characters.',
                (value, context) => {
                    const isValidDisplaName = displayNameRegex.test(value || '');
                    return isValidDisplaName;
                }
            )
            .test(
                'is-duplicate-displayName',
                'An account with this display name already exists. Use another display name.',
                (value, context) => {
                    const isUnique = (context?.from || [{}])[0]?.value?.displayNameUnique;
                    return isUnique;
                }
            ),
        organization: yup.string().required("Cannot be empty. Enter organization name."),
        remarks: yup.string(),
        localGovernment: yup.string(),
        usernameAdministeredBy: yup.string().required("Cannot be empty. Select an appropriate area."),
        accessEnded: yup.string(),
        accessStartDate: yup.string().typeError("Date is invalid")
            .nullable()
            .required("Start date cannot be empty. Enter a date.")
            .test(
                'is-less-than-endDate',
                'This date must be before or the same as access end date.',
                (value, context) => {

                    const asDt = (value ? dayjs(value).tz().endOf('day') : null);
                    const aeDt = wdpUtil.isNullorUndefined(context.parent.accessEndDate) ? null : dayjs(context.parent.accessEndDate).tz().endOf('day');
                    const isLess = !aeDt || asDt! <= aeDt;
                    return isLess;

                }
            )
            .test(
                'is-greater-than-today',
                'This date cannot be in the past. It can be today or in the future.',
                (value, context) => {
                    context.from = context.from || [];
                    const id = context.from[0]?.value.userId;
                    let isTodayOrLater = true;
                    if (id === "new") {
                        const todayDt = dayjs().tz().startOf('day');
                        const asDt = dayjs(value).tz().startOf('day');
                        isTodayOrLater = (asDt >= todayDt);

                    }
                    return isTodayOrLater;
                }
            )
        ,
        accessEndDate: yup.string().typeError("Date is invalid")
            .nullable()
            .test(
                'is-greater-than-startDate',
                'This date must be today or later than access start date.',
                (value, context) => {
                    if (!value) return true;
                    const aeDt = dayjs(value).tz().endOf('day');
                    const asDt = wdpUtil.isNullorUndefined(context.parent.accessStartDate) ? null : dayjs(context.parent.accessStartDate).tz().endOf('day');

                    const isGreater = !asDt || aeDt >= asDt;

                    context.from = context.from || [];
                    const id = context.from[0]?.value.userId;
                    let isTodayOrLater = true;
                    if (id === "new") {
                        const todayDt = dayjs().tz().startOf('day');
                        const aeDt = dayjs(value).tz().startOf('day');
                        isTodayOrLater = (aeDt >= todayDt);
                    }
                    return isTodayOrLater && isGreater;
                }
            ),
    }, [["accessStartDate", "accessEndDate"]]);

    return userSchema;
};
var schema = getUserSchema('');
type UserInfo = InferType<typeof schema>;

const initialValues: UserInfo = {
    userId: "",
    firstName: "",
    lastName: "",
    email: "",
    emailUnique: true,
    displayNameUnique: true,
    accessStartDate: "",
    accessEndDate: "",
    phone: "",
    middleName: "",
    displayName: "",
    organization: "",
    remarks: "",
    localGovernment: "",
    usernameAdministeredBy: "",
    accessEnded: "No",
}

export function UserDetails(props: { onChange: any }) {
    const auth = useAuth();
    const [refresh, setRefresh] = useState(true);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [localGovList, setLocalGovList] = useState([]);

    const onSubmit = (data: any) => {
        debugger;
        const email = data.email;
        const dName = data.displayName;

        ApiService.isDisplayNameExist(auth.user?.access_token!, dName, data.userId!).then(exist => {
            formik.setFieldValue('displayNameUnique', !exist);
            if (!exist) {
                if (userId === 'new') {
                    ApiService.isEmailExist(auth.user?.access_token!, email).then(exist => {
                        formik.setFieldValue('emailUnique', !exist);
                        if (!exist) {
                            execSubmit(data);
                        }
                    });
                } else {
                    debugger;
                    execSubmit(data);
                }
            }
        });
    }
    const execSubmit = (data: any) => {
        // reset touched
        formik.setTouched({}, false);
        if (userId === 'new') {

            data.accessEndDate = (wdpUtil.isNullorUndefined(data.accessEndDate) || data.accessEndDate.length === 0) ? null : data.accessEndDate;
            data.username = data.email;
            // check Display Name uniqunes
            ApiService.addUser(auth.user?.access_token as string, data).then(res => {
                formik.setSubmitting(false);
                // Samer in case of null
                if (!wdpUtil.isNullorUndefined(res)) {
                    console.log(res);
                    setRedirectId(res.userId);
                    setRefresh(!refresh);
                    handleToastOpen("New user created successfully");


                }
                else {
                    // backend failed
                    handleToastOpen("failed to create new user", "emergency");
                }
            }).catch((error) => {
                console.log(error);
                handleToastOpen(`failed to create new user. ${error?.response?.data}`, "emergency");
            }).finally(() => { });
        } else {
            //data.accessEndDate =  (data.accessEndDate ? zonedTimeToUtc(startOfDay(data.accessEndDate),tz)!.toISOString() : null);
            data.accessEndDate = (wdpUtil.isNullorUndefined(data.accessEndDate) || data.accessEndDate.length === 0) ? null : data.accessEndDate;
            console.log(data.accessStartDate);
            console.log(data.accessEndDate);
            ApiService.updateUser(auth.user?.access_token as string, userId as string, data).then(res => {
                formik.setSubmitting(false);
                setRefresh(!refresh);
                if (typeof props.onChange === "function") {
                    props.onChange(data);
                }
                handleToastOpen("User updated successfully");
            }).catch((error) => {
                console.log(error);
                handleToastOpen(`failed to update user. ${error?.response?.data}`, "emergency");
            }).finally(() => { });
        }
    }
    const getSchema = useCallback(() => {
        return getUserSchema(auth.user?.access_token as string);
    }, [auth.user?.access_token]);
    const formik = useFormik({
        initialValues,
        validationSchema: getSchema(),
        onSubmit: onSubmit
    });
    const [redirectId, setRedirectId] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const { userId } = useParams();
    const navigate = useNavigate();
    const [toastOpen, setToastOpen] = useState(false);
    const [toastMsg, setToastMsg] = useState("");
    const [toastType, setToastType] = useState<GoACalloutType | undefined>('success');
    const [showModal, setShowModal] = useState({ cb: (cancelled = true) => { }, show: false });
    const [displayNameSuggEnable, setDisplayNameSuggEnable] = useState(true);

    /****************************************************************************************
     * 
    *****************************************************************************************/
    const handleToastOpen = (msg: string, type: GoACalloutType | undefined = "success") => {
        setToastMsg(msg);
        setToastType(type)
        setToastOpen(true);
    };
    /****************************************************************************************
     * 
    *****************************************************************************************/
    const handleToastClose = () => {
        setToastOpen(false);

        if (userId === "new" && redirectId) {
            navigate(`/user-ctrl/${redirectId}`);
        }
    };
    /****************************************************************************************
     * 
    *****************************************************************************************/
    useEffect(() => {
        ApiService.getLocalGovernments(auth.user?.access_token as string)
            .then(res => {
                setLocalGovList(res);
                if (userId !== 'new') {
                    ApiService.getUsersById(auth.user?.access_token as string, userId as string).then(res => {

                        //res.accessStartDate =  wdpUtil.isNullorUndefined(res.accessStartDate) ? null : dayjs.utc(res.accessStartDate).tz(tz).startOf("day");
                        //res.accessEndDate =  wdpUtil.isNullorUndefined(res.accessEndDate) ? null : dayjs.utc(res.accessEndDate).tz(tz).startOf("day");
                        //res.accessStartDate =  wdpUtil.isNullorUndefined(res.accessStartDate) ? null : startOfDay(utcToZonedTime(res.accessEndDate,tz));
                        //res.accessEndDate   =  wdpUtil.isNullorUndefined(res.accessEndDate) ? null : startOfDay(utcToZonedTime(res.accessEndDate,tz));
                        if (res !== null) {
                            populateForm(res);
                        } else {
                            console.log(`Failed to Load user details. UserID = ${userId}`);
                        }
                    }).catch((error) => {
                        console.log(error);
                    }).finally(() => setIsLoading(false));
                } else {
                    populateForm(null);
                    setIsLoading(false);

                }
            })
            .catch(error => console.error(error));


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.user?.access_token, userId, refresh]);

    useEffect(() => {
        const el = document.querySelector('.Mui-error, [data-error]');
        if (!wdpUtil.isNullorUndefined(el)) {
            (el?.parentElement ?? el)?.scrollIntoView();
            (el?.firstChild as HTMLElement).focus();
        }
    }, [formik.isSubmitting]);
    /********************************************************************************************
     * 
    
    ********************************************************************************************/
    function populateForm(res: UserInfo | null) {
        if (res === null) {
            formik.setFieldValue("userId", "new");
        }
        else {

            formik.setFieldValue("userId", res.userId);
            formik.setFieldValue("id", res.id);
            formik.setFieldValue("createdOn", res.createdOn);
            formik.setFieldValue("createdByEmail", res.createdByEmail);
            formik.setFieldValue("createdByDisplayName", res.createdByDisplayName);
            formik.setFieldValue("createdByUserId", res.createdByUserId);
            formik.setFieldValue("updatedOn", res.updatedOn);
            formik.setFieldValue("updatedByEmail", res.updatedByEmail);
            formik.setFieldValue("updatedByDisplayName", res.updatedByDisplayName);
            formik.setFieldValue("updatedByUserId", res.updatedByUserId);
            formik.setFieldValue("email", res.email);
            formik.setFieldValue('emailUnique', true); // loading an existing user ...must be unique

            formik.setFieldValue("firstName", res.firstName ?? '');
            formik.setFieldValue("middleName", res.middleName ?? "");
            formik.setFieldValue("lastName", res.lastName ?? '');

            formik.setFieldValue("accessStartDate", res.accessStartDate ?? null);
            formik.setFieldValue("accessEndDate", res.accessEndDate ?? null);
            formik.setFieldValue("phone", res.phone ?? "");

            formik.setFieldValue("organization", res.organization ?? "");
            formik.setFieldValue("remarks", res.remarks ?? "");
            formik.setFieldValue("usernameAdministeredBy", res.usernameAdministeredBy ?? "");
            formik.setFieldValue("localGovernment", res.localGovernment ?? "");
            formik.setFieldValue("accessEnded", res.accessEnded !== "Yes" ? "No" : res.accessEnded);

            let dName = res.displayName;
            if (wdpUtil.isNullorUndefined(dName) || dName?.length === 0) {
                // auto populate Display Name (if null)
                dName = suggestDisplayName(res?.firstName, res?.middleName, res?.lastName);
            } else {
                // turn Off DisplayName suggestion 
                setDisplayNameSuggEnable(false);
            }
            // check if   unique ?
            if (!wdpUtil.isNullorUndefined(dName) && dName?.length) {
                ApiService.isDisplayNameExist(auth.user?.access_token!, dName, res.userId!).then(exist => {
                    formik.setFieldValue('displayNameUnique', !exist);
                });
            }
            // populate it
            formik.setFieldValue("displayName", dName)
        }
    }
    /********************************************************************************************
     * 
     * this Effect is just to populate the DisplayName from FNamelastName,Mid Name 
    ********************************************************************************************/
    useEffect(() => {
        if (displayNameSuggEnable) {
            const sugDName = suggestDisplayName(formik.values.firstName, formik.values.middleName, formik.values.lastName);
            if (sugDName !== formik.values.displayName) {
                formik.setFieldValue("displayName", sugDName);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.firstName, formik.values.middleName, formik.values.lastName]);
    /********************************************************************************************
     * 
     * @param name 
     * @param value 
     *******************************************************************************************/
    function onNamesBlur() {
        const sugDName = formik.values.displayName;
        const isValidDisplaName = displayNameRegex.test(sugDName || '');
        if (isValidDisplaName) {
            ApiService.isDisplayNameExist(auth.user?.access_token!, sugDName, formik.values.userId!).then(exist => {
                formik.setFieldValue('displayNameUnique', !exist);
            })
        }
    }
    function onEmailBlur() {
        const email = formik.values.email;
        const isValidEmail = emailRegex.test(email || '');
        if (isValidEmail) {
            ApiService.isEmailExist(auth.user?.access_token!, email).then(exist => {
                formik.setFieldValue('emailUnique', !exist);
            })
        }
    }
    /****************************************************************************************************************
     * this function will populate the display name field with a suggested name based on the 
     * following formula: 7 chars of First name, 1st char of Middle name, 7 chars of Last name: 7+1+7.
     ****************************************************************************************************************/
    function suggestDisplayName(fname: string, mname: string | undefined, lname: string) {
        let sugVal = '';
        fname = fname || '';
        mname = mname || '';
        lname = lname || '';

        // strip the special characters 
        if (fname) {
            fname = fname.replace(/[^a-zA-Z0-9]/g, '');
        }
        if (mname) {
            mname = mname.replace(/[^a-zA-Z0-9]/g, '');
        }
        if (lname) {
            lname = lname.replace(/[^a-zA-Z0-9]/g, '');
        }

        const fnamePart = fname ? (fname.length >= 7 ? fname.slice(0, 7) : fname) : '';
        const mnamePart = mname ? mname[0] : '';
        const lnamePart = lname.slice(0, 7);

        if (fnamePart && lnamePart) {
            sugVal = `${fnamePart}${mnamePart}${lnamePart}`;
        }
        return sugVal;
    }
    const setFieldValueSync = async (name: string, value: string, touched?: boolean) => {
        formik.setFieldValue(name, value);
        if (!wdpUtil.isNullorUndefined(touched)) {
            // this is to trigger the validation on the new value so we need to wait till setFieldValue is done. 
            // setFieldValue callback is not working (cant do '.then') that is why we will use setTimeout.
            // with 0 delay we are waiting for the call stack to be empty in order to execute the callback.
            setTimeout(() => { formik.setFieldTouched(name, true); });
        }
    };
    /********************************************************************************************
     * 
     * @param fieldName 
     * @param value 
    *********************************************************************************************/
    function onDateChange(event: any) {
        //console.log(value);
        const who = event.target.name;
        let dt: Dayjs | null = event.target.value;
        const validDt = (dt !== null && !isNaN(dt.toDate().getTime()));

        const dtStr = validDt ? dayjs.utc(dayjs(dt).tz().startOf('day')).toISOString() : null;
        formik.setFieldValue(who, dtStr, true);
        //formik.handleChang();
    }
    /********************************************************************************************
     * 
     * @param event
    *********************************************************************************************/
    function onDateBlur(event: any) {
        formik.handleBlur(event);
    }
    /********************************************************************************************
     * 
     * @param fieldName 
    *********************************************************************************************/
    function populateDT(fieldName: string) {
        //samerconst aDt       = (fieldName === "accessStartDate")? (formik.values.accessStartDate? utcToZonedTime(formik.values.accessStartDate,tz) : null): (formik.values.accessEndDate? utcToZonedTime(formik.values.accessEndDate,tz) : null)
        const dtStr = fieldName === "accessStartDate" ? formik.values.accessStartDate : formik.values.accessEndDate;

        const aDt = (dtStr ? dayjs(dtStr).tz() : null);
        const aTouched = (fieldName === "accessStartDate") ? formik.touched.accessStartDate : formik.touched.accessEndDate;
        const aERrror = (fieldName === "accessStartDate") ? formik.errors.accessStartDate : formik.errors.accessEndDate;
        //const icon      = ((!wdpUtil.isNullorUndefined(aERrror) && aERrror!.length !== 0) && (!wdpUtil.isNullorUndefined(aTouched) && aTouched === true))?'close' : 'calendar';
        return (
            <CustomFmF name={fieldName}
                value={aDt}
                //onChange={formik.handleChange}
                onChange={(event: any) => onDateChange(event)}
                onBlur={(event: any) => onDateBlur(event)}
                error={aTouched && aERrror}
            />
            // <DatePicker icon={ icon} 
            //     format="yyyy-MM-dd" 
            //     value={aDt} 
            //     name={fieldName} 
            //     timezone={tz}
            //     //onChange={formik.handleChange} 
            //     //onBlur={formik.handleBlur} 
            //     //onChange={(value) => {alert(value);formik. handleChange({ target: { fieldName, value } })}} 
            //     //onBlur={(val: any) => { formik.handleBlur(fieldName)}}
            //     onChange={(value) => {onDateChange(fieldName, value) }} 
            //     onBlur={(val: any) => { formik.setFieldTouched(fieldName,true)}}

            //     // error={formik.touched.accessEndDate && formik.errors.accessEndDate}
            // />
        );
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////
    // Main return
    ////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <>
            {isLoading &&
                <div className="spinner">
                    <GoACircularProgress variant="fullscreen" size="large" message="Loading user's details..." visible={true} />
                </div>
            }
            {!isLoading &&
                <div className="page">
                    <form onSubmit={formik.handleSubmit}>
                        <section className="field">
                            <section className="label-wrapper">
                                <label htmlFor="firstName">First name</label>
                                <span className="required">* <span>(required)</span></span>
                            </section>
                            {/* This input is required to hide input suggestions on Chrome */}
                            <input style={{ display: "none" }} autoComplete="on" />
                            <TextField id="outlined-basic"
                                variant="outlined"
                                fullWidth={true}
                                inputProps={{
                                    autoComplete: "new-password"
                                }}
                                onChange={(e) => {
                                    formik.handleChange(e)
                                }}
                                onBlur={(e) => {
                                    onNamesBlur();
                                    formik.handleBlur(e);
                                }}
                                value={formik.values.firstName}
                                focused={true}
                                name="firstName"
                                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                            />
                            {formik.touched.firstName && Boolean(formik.errors.firstName) &&
                                <section className="error">
                                    <GoAIcon type="warning" theme="filled" size="small" />
                                    <span > {formik.errors.firstName}</span>
                                </section>
                            }
                        </section>

                        <section className="field">
                            <section className="label-wrapper">
                                <label htmlFor="middleName">Middle name</label>
                            </section>
                            <TextField id="outlined-basic"
                                variant="outlined"
                                fullWidth={true}
                                inputProps={{
                                    autoComplete: "new-password"
                                }}
                                onChange={(e) => {
                                    formik.handleChange(e)
                                }}
                                onBlur={(e) => {
                                    onNamesBlur();
                                    formik.handleBlur(e);
                                }}
                                value={formik.values?.middleName as string}
                                name="middleName"
                                error={formik.touched.middleName && Boolean(formik.errors.middleName)}
                            />
                            {formik.touched.middleName && Boolean(formik.errors.middleName) &&
                                <section className="error">
                                    <GoAIcon type="warning" theme="filled" size="small" />
                                    <span > {formik.errors.middleName}</span>
                                </section>
                            }
                        </section>

                        <section className="field">
                            <>
                                <section className="label-wrapper">
                                    <label htmlFor="lastName">Last name</label>
                                    <span className="required">* <span>(required)</span></span>
                                </section>
                                <TextField id="outlined-basic"
                                    variant="outlined"
                                    fullWidth={true}
                                    inputProps={{
                                        autoComplete: "new-password"
                                    }}
                                    onChange={(e) => {
                                        formik.handleChange(e)
                                    }}
                                    onBlur={(e) => {
                                        onNamesBlur();
                                        formik.handleBlur(e);
                                    }}
                                    value={formik.values.lastName}
                                    name="lastName"
                                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                />
                                {formik.touched.lastName && Boolean(formik.errors.lastName) &&
                                    <section className="error">
                                        <GoAIcon type="warning" theme="filled" size="small" />
                                        <span > {formik.errors.lastName}</span>
                                    </section>
                                }
                            </>
                        </section>

                        <section className="field">
                            <section className="label-wrapper">
                                <label htmlFor="displayName">Display name</label>
                                <span className="required">* <span>(required)</span></span>
                            </section>
                            <TextField id="outlined-basic"
                                variant="outlined"
                                fullWidth={true}
                                inputProps={{
                                    autoComplete: "new-password"
                                }}
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    const value = e?.target?.value;
                                    if (value !== formik.values?.displayName) {
                                        // real change..user is typing ..this is to prevent displayname Suggestion
                                        if (value && value.length) {
                                            setDisplayNameSuggEnable(false);
                                        } else {
                                            //clean
                                            setDisplayNameSuggEnable(true);
                                        }
                                    }
                                }}
                                onBlur={(e) => {
                                    if (formik.values.displayName === "") {
                                        // Why: user Entered a FName,MName and LastName and left DName empty(focusout).
                                        // this code is to force the suggestion and validate onBlur of the displayName
                                        // setField synchronously because we need to validate onNameBlur  right after 
                                        const dName = suggestDisplayName(formik.values.firstName, formik.values.middleName, formik.values.lastName);
                                        setFieldValueSync("displayName", dName, true);
                                    }
                                    onNamesBlur();
                                    formik.handleBlur(e);
                                }}
                                value={formik.values?.displayName as string}
                                name="displayName"
                                error={formik.touched.displayName && Boolean(formik.errors.displayName)}
                            />
                            <p className="sub-text">Contains only letters and numbers. Maximum length is 15 characters. Suggested Display names are 7 chars of First name, 1st char of Middle name, 7 chars of Last name: 7+1+7.</p>
                            {formik.touched.displayName && Boolean(formik.errors.displayName) &&
                                <section className="error">
                                    <GoAIcon type="warning" theme="filled" size="small" />
                                    <span > {formik.errors.displayName}</span>
                                </section>
                            }
                        </section>
                        <section className="field">
                            <section className="label-wrapper">
                                <label htmlFor="email">Username (email)</label>
                                <span className="required">* <span>(required)</span></span>
                            </section>
                            <TextField id="outlined-basic"
                                variant="outlined"
                                fullWidth={true}
                                inputProps={{
                                    autoComplete: "new-password"
                                }}
                                onChange={(e) => {
                                    formik.handleChange(e)
                                }}
                                onBlur={(e) => {
                                    if (userId === 'new') {
                                        onEmailBlur();
                                    }
                                    formik.handleBlur(e)

                                }}
                                value={formik.values.email}
                                name="email"
                                disabled={userId !== "new"}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                            />
                            {formik.touched.email && Boolean(formik.errors.email) &&
                                <section className="error">
                                    <GoAIcon type="warning" theme="filled" size="small" />
                                    <span > {formik.errors.email}</span>
                                </section>
                            }
                        </section>

                        <section className="field">
                            <section className="label-wrapper">
                                <label htmlFor="phone">Phone</label>
                            </section>
                            <TextField id="outlined-basic"
                                variant="outlined"
                                fullWidth={true}
                                inputProps={{
                                    autoComplete: "new-password"
                                }}
                                onChange={(e) => {
                                    formik.handleChange(e)
                                }}
                                onBlur={(e) => {
                                    formik.handleBlur(e)
                                }}
                                value={formik.values.phone as string}
                                name="phone"
                                error={formik.touched.phone && Boolean(formik.errors.phone)}
                            />
                            {formik.touched.phone && Boolean(formik.errors.phone) &&
                                <section className="error">
                                    <GoAIcon type="warning" theme="filled" size="small" />
                                    <span > {formik.errors.phone}</span>
                                </section>
                            }
                        </section>

                        <section className="field">
                            <section className="label-wrapper">
                                <label htmlFor="organization">Organization</label>
                                <span className="required">* <span>(required)</span></span>
                            </section>
                            <TextField id="outlined-basic"
                                variant="outlined"
                                fullWidth={true}
                                inputProps={{
                                    autoComplete: "new-password"
                                }}
                                onChange={(e) => {
                                    formik.handleChange(e)
                                }}
                                onBlur={(e) => {
                                    formik.handleBlur(e)
                                }}
                                value={formik.values.organization}
                                name="organization"
                                error={formik.touched.organization && Boolean(formik.errors.organization)}
                            />
                            {formik.touched.organization && Boolean(formik.errors.organization) &&
                                <section className="error">
                                    <GoAIcon type="warning" theme="filled" size="small" />
                                    <span > {formik.errors.organization}</span>
                                </section>
                            }
                        </section>
                        <section className="field">
                            <section className="label-wrapper">
                                <label htmlFor="usernameAdministeredBy">Username administered by</label>
                                <span className="required">* <span>(required)</span></span>
                            </section>
                            <div onBlur={(e) => { formik.handleBlur(e) }}>
                                <GoADropdown name="usernameAdministeredBy"
                                    width="700px"
                                    error={formik.touched.usernameAdministeredBy && Boolean(formik.errors.usernameAdministeredBy)}
                                    onChange={(name, value) => {
                                        formik.handleChange({ target: { name, value } })
                                    }}
                                    value={formik.values.usernameAdministeredBy}>
                                    {adminAreas.map((item, index) => <GoADropdownItem key={index} value={item} label={item} />)}
                                </GoADropdown>
                            </div>
                            {formik.touched.usernameAdministeredBy && Boolean(formik.errors.usernameAdministeredBy) &&
                                <section className="error">
                                    <GoAIcon type="warning" theme="filled" size="small" />
                                    <span > {formik.errors.usernameAdministeredBy}</span>
                                </section>
                            }
                        </section>

                        <section className="field">
                            <section className="label-wrapper">
                                <label htmlFor="localGovernment">Local government</label>
                            </section>
                            <div onBlur={(e) => { formik.handleBlur(e) }}>
                                <GoADropdown name="localGovernment"
                                    width="700px"
                                    error={formik.touched.localGovernment && Boolean(formik.errors.localGovernment)}
                                    onChange={(name, value) => {
                                        formik.handleChange({ target: { name, value } })
                                    }}
                                    value={formik.values.localGovernment}>
                                    {localGovList.map((item: any, index) => <GoADropdownItem key={index} value={item.localGov} label={item.localGov} />)}
                                </GoADropdown>
                            </div>
                        </section>

                        <section className="field access-ended">
                            <section className="label-wrapper">
                                <label htmlFor="accessEndDate">Access ended ?</label>
                            </section>
                            <section>
                                <RadioGroup row
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="female"
                                    name="accessEnded"
                                    value={formik.values.accessEnded}
                                    onBlur={(e) => {
                                        formik.handleBlur(e);
                                    }}
                                    onChange={(event) => {
                                        setShowModal({
                                            cb: (cancelled = true) => {
                                                //samerconst todayDt = zonedTimeToUtc(startOfDay(new Date()), tz);
                                                const todayDt = dayjs().tz(tz).startOf('day');
                                                if (cancelled === true) {
                                                    const rValue = event.target.value === "Yes" ? "No" : "Yes";
                                                    formik.setFieldValue("accessEnded", rValue);
                                                } else {
                                                    formik.handleChange(event);
                                                    if (event.target.value === "Yes") {
                                                        setFieldValueSync("accessEndDate", todayDt.toISOString(), true);
                                                        //formik.setFieldTouched("accessEndDate", true, false);
                                                        //formik.validateForm({ ...formik.values, ["accessEndDate"]: todayDt.toISOString() });
                                                    } else {
                                                        formik.setFieldValue("accessEndDate", undefined);
                                                    }
                                                }
                                            }, show: true
                                        });
                                    }}
                                >
                                    <FormControlLabel disabled={formik.values.accessEnded === 'Yes'} value="Yes" control={<Radio />} label="Yes" />
                                    <FormControlLabel disabled={formik.values.accessEnded === 'Yes'} value="No" control={<Radio />} label="No" />
                                </RadioGroup>
                            </section>
                            <p className="sub-text">Sets the access end date to today, and removes user access immediately.</p>
                            <GoAModal
                                open={showModal.show}
                                maxWidth="480px"
                                actions={
                                    <GoAButtonGroup alignment="end">
                                        <GoAButton testId='ud-access-ended-modal-no' type="secondary" onClick={() => {
                                            showModal.cb();
                                            setShowModal({ cb: () => { }, show: false });
                                        }}>No, Cancel</GoAButton>
                                        <GoAButton testId='ud-access-ended-modal-yes' onClick={() => {
                                            showModal.cb(false);
                                            setShowModal({ cb: () => { }, show: false })
                                        }}>Yes, I do</GoAButton>
                                    </GoAButtonGroup>
                                }
                            >
                                {formik.values.accessEnded === "Yes" ?
                                    <p>Are you sure you want to give access to {formik.values.firstName} {formik.values.lastName}</p>
                                    :
                                    <p>Are you sure you want to end access for {formik.values.firstName} {formik.values.lastName}</p>
                                }
                            </GoAModal>
                        </section>

                        <div className="date-grid field">
                            <section>
                                <section className="label-wrapper">
                                    <label htmlFor="accessStartDate">Access start date</label>
                                    <span className="required">* <span>(required)</span></span>
                                </section>
                                <div>
                                    <section className="date-field">
                                        {populateDT('accessStartDate')}
                                    </section>
                                    <span className="sub-text">00:00:00&nbsp;AM</span>
                                </div>
                                {formik.touched.accessStartDate && formik.errors.accessStartDate &&
                                    <section className="error">
                                        <GoAIcon type="warning" theme="filled" size="small" />
                                        <span > {formik.errors.accessStartDate as any}</span>
                                    </section>
                                }
                            </section>
                            <section>
                                <section className="label-wrapper">
                                    <label htmlFor="accessEndDate">Access end date</label>
                                </section>
                                <div>
                                    <section className="date-field">
                                        {populateDT('accessEndDate')}
                                    </section>
                                    <span className="sub-text">23:59:59&nbsp;PM</span>
                                </div>
                                {formik.touched.accessEndDate && formik.errors.accessEndDate &&
                                    <section className="error">
                                        <GoAIcon type="warning" theme="filled" size="small" />
                                        <span > {formik.errors.accessEndDate as any}</span>
                                    </section>
                                }
                            </section>
                        </div>

                        {/* {formik.values?.createdTimestamp &&
                            <section className="field">
                                <section className="label-wrapper">
                                    <label htmlFor="accessEndDate">Created at</label>
                                </section>
                                <span>{format(new Date(formik.values?.createdTimestamp as number), 'yyyy-MM-dd    HH:mm:ss')}</span>
                            </section>
                        } */}

                        <section className="field" onBlur={(e) => { formik.handleBlur(e) }}>
                            <section className="label-wrapper">
                                <label htmlFor="remarks">Remarks</label>
                            </section>
                            <GoATextArea
                                onChange={(name, value) => {
                                    formik.handleChange({ target: { name, value } })
                                }}
                                value={formik.values.remarks as string}
                                name="remarks"
                                error={!!formik.errors.remarks}
                                width="700px"
                            />
                            {formik.touched.remarks && formik.errors.remarks &&
                                <section className="error">
                                    <GoAIcon type="warning" theme="filled" size="small" />
                                    <span > {formik.errors.remarks}</span>
                                </section>
                            }
                        </section>
                        <section className="save">
                            <button className="save-button" data-testid="ud-page-save" type="submit"
                            //disabled={!formik.isValid || !formik.dirty }
                            //disabled={!formik.isValid || (Object.keys(formik.touched).length === 0 )}
                            >Save</button>
                            {/* <GoAButton type="submit" onClick={() => { (formEl.current as any).submit() }}>Save</GoAButton> */}
                            <GoASpacer hSpacing="s"></GoASpacer>{toastOpen}
                            <GoAButton testId="ud-page-cancel" onClick={() => setShowCancelModal(true)} type="secondary">Cancel</GoAButton>
                        </section>
                    </form >
                    <Toast open={toastOpen} onClose={handleToastClose} >
                        <GoACallout type={toastType} >
                            <div className="toastContent">
                                <span>{toastMsg}</span>
                                <GoAIconButton icon="close" onClick={() => {
                                    handleToastClose();
                                }}></GoAIconButton>
                            </div>
                        </GoACallout>
                    </Toast>
                    <GoAModal heading="" open={showCancelModal} maxWidth="30vw"
                        actions={
                            <GoAButtonGroup alignment="end">
                                <GoAButton testId='ud-cancel-modal-no' type='secondary' onClick={() => { setShowCancelModal(false); }}>No, continue editing</GoAButton>
                                <GoAButton testId='ud-cancel-modal-yes' type='primary' onClick={() => { navigate("/") }}>Yes, I am sure</GoAButton>
                            </GoAButtonGroup>
                        }
                    >
                        <p>Are you sure you want to discard the changes?</p>
                    </GoAModal>
                </div >
            }
        </>
    );
}