import axios from "axios";
import { apimUrl } from "../common/constants";
import { wdpUtil } from "../common/utils/util";
import { RolePlanInfo } from "../common/models/roleInfo";
import { hasResourceRole } from "../common/utils/tokenUtil";

const baseUrl = `${apimUrl}`;
const usersUrl = `${baseUrl}/users`;
const rolesUrl = `${baseUrl}/groups`;

const getConfig = (token: string) => {
  return {
    // withCredentials: true,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF": "1",
      Authorization: `Bearer ${token}`,
    },
  };
};

export default class ApiService {
  config: any;
  /**
   *
   * @param token the access_token
   */

  // user management
  public static getUsers = async (token: string, search: string, sortCol:string,sortDir:string,pageNo:number,perPage:number,controller: AbortController) => {
    const url = `${usersUrl}?search=${encodeURIComponent(search)}&sortCol=${sortCol}&sortDir=${sortDir}&pageNo=${pageNo}&perPage=${perPage}`;
    return axios.get(url, { ...getConfig(token), signal: controller?.signal }).then((res) => {
      return  res.data;
    });
  };
  public static getLocalGovernments = async (token: string) => {
    const url = `${baseUrl}/local-governments`;
    return axios.get(url, { ...getConfig(token)}).then((res) => {
      return  res.data;
    });
  };
  
  public static isEmailExist = async (token: string, email: string) => {
    const url = `${usersUrl}/email-exists?email=${encodeURIComponent(email)}`;
    return axios.get(url, getConfig(token)).then((res) => {
      let exists = res.data;
      return exists;
    });
  };
  public static isDisplayNameExist = async (token: string, displayName: string,userID:string ) => {
    const url = `${usersUrl}/displayName-exists?displayName=${encodeURIComponent(displayName)}&userID=${userID}`;
    return axios.get(url, getConfig(token)).then((res) => {
      let exists = res.data;
      return exists;
    });
  };
  

  public static getUsersById = async (token: string, userId: string) => {
    return axios.get(`${usersUrl}/${userId}`, getConfig(token)).then((res) => {
      return res.data;
    });
  };

  public static updateUser = async (token: string,id: string,userInfo: any) => {
    return axios.put(`${usersUrl}/${id}`, userInfo, getConfig(token)).then((res) => {
      return res.data;
    });
  };

  public static addUser = (token: string, userInfo: any) => {
    return axios.post(`${usersUrl}`, userInfo, getConfig(token)).then((res) => {
      return res.data;
    });
  };

  // role management
  public static getRoles = (token: string, search: string = "") => {
    return axios.get(`${rolesUrl}?search=${search}`, getConfig(token)).then((res) => {
      return res.data;
    });
  };
  // role management
  public static getPermissions = (token: string) => {
    return axios.get(`${baseUrl}/permissions`, getConfig(token)).then((res) => {
      return res.data;
    });
  };
  public static getRoleDefs = (token: string, search: string = "", sortCol:string,sortDir:string,pageNo:number,perPage:number,controller: AbortController) => {
    return axios.get(`${baseUrl}/role-definition?search=${encodeURIComponent(search)}&sortCol=${sortCol}&sortDir=${sortDir}&pageNo=${pageNo}&perPage=${perPage}`, getConfig(token)).then((res) => {
      return res.data;
    });
  };
  public static getRoleDefById = (token: string, roleId: string) => {
    return axios.get(`${baseUrl}/role-definition/${(roleId)}`, getConfig(token)).then((res) => {
      return res.data;
    });
  };

  public static addRole = (token: string, roleInfo: any) => {
    return axios.post(`${baseUrl}/role-definition`, roleInfo, getConfig(token)).then((res) => {
      return res.data;
    });
  };

  public static editRole = (token: string, roleInfo: any) => {
    return axios.put(`${baseUrl}/role-definition`, roleInfo, getConfig(token)).then((res) => {
      return res.data;
    });
  };

  public static getUserRoles = async (token: string, userId: string) => {
    return axios.get(`${usersUrl}/${userId}/groups`, getConfig(token)).then((res) => {
      return res.data;
    });
  };

  public static getRolePlans = (token: string, userId: string) => {
    return axios.get(`${baseUrl}/role-plans/${userId}`, getConfig(token)).then((res) => {
      return res.data;
    });
  };

  public static createRolePlans = (token: string, plans: RolePlanInfo[]) => {
    return axios.post(`${baseUrl}/role-plans`, plans, getConfig(token)).then((res) => {
      return res.data;
    });
  };
  public static updateRolePlans = (token: string, plans: RolePlanInfo[]) => {
    return axios.put(`${baseUrl}/role-plans`, plans, getConfig(token)).then((res) => {
      return res.data;
    });
  };

  public static getRolesAll = async (token: string,search: string = "",userId: string) => {
    return axios.get(`${rolesUrl}?search=${search}`, getConfig(token)).then((res) => {
        // first query all roles (keycloak query)
        if (!wdpUtil.isNullorUndefined(res.data)) {
          let allRoles = res.data;

          // get all plans for the given user and do the intersection
          return this.getRolePlans(token, userId)
            .then((userPlans) => {
              let allPlans: any = []; //userPlans;

              allRoles.forEach((role: any) => {
                var pindex = userPlans.findIndex(
                  (plan: any) => role.id === plan.roleId
                );
                if (pindex === -1) {
                  // no plan for this role .. take it
                  allPlans = [
                    ...allPlans,
                    {
                      roleId: role.id,
                      name: role.name,
                      isRoleAdmin: this.isAdminForRole(token, role.adminPermissionName),
                      isRoleOwner: this.isRoleOwner(token, role.ownerPermissionName),
                      userId: userId,
                      grantedStart: null,
                      grantedEnd: null,
                      description: role.description
                    },
                  ];
                } else {
                  // found it in the plans, give it a name  and take it
                  const goodOne = userPlans[pindex];
                  goodOne.name = role.name;
                  goodOne.isRoleAdmin = this.isAdminForRole(token, role.adminPermissionName);
                  goodOne.isRoleOwner = this.isRoleOwner(token, role.ownerPermissionName);
                  goodOne.description = role.description;
                  allPlans.push(goodOne);
                }
              }); //for each
              //console.log("Data Raw" + JSON.stringify(allPlans));
              return allPlans;
            })
            .catch((e: any) => {
              console.log(e.message);
            });
        }
      })
      .catch((e: any) => {
        console.log(e.message);
      }); // fist Get then
  };

  static isAdminForRole(token: string, permission: string): boolean {
    let isAdmin = hasResourceRole('wildfire-role-admin', permission, token);
    return isAdmin;
  }
  static isRoleOwner(token: string, permission: string): boolean {
    let isOwner = hasResourceRole('wildfire-role-owner', permission, token);
    return isOwner;
  }

  // rules engine
  public static getRule = async (token: string) => {
    const url = `https://wmtt-rules-engine.azurewebsites.net/api/v1.0/rules-engine/rules?feature=AviationReporting&api=CreateFlightReport&isFrontEndValidation=false`;
    return axios.get(url, getConfig(token)).then((res) => {
      return res.data;
    });
  };
  /******************************************************************************************
   *
   * @returns
   *******************************************************************************************/
  public static getRoles4User = (token: string, userId: string) => {
    return axios.get(`${usersUrl}/${userId}/groups`, getConfig(token)).then((res) => {
      return res.data;
    });
  };
  /******************************************************************************************
   *
   * @returns
   *******************************************************************************************/
  public static getRolePlans4User = (token: string, userId: string) => {
    return axios.get(`${baseUrl}/role-plans/${userId}`, getConfig(token)).then((res) => {
      return res.data;
    });
  };
  /******************************************************************************************
   *
   * @returns
  *******************************************************************************************/
  public static sendRoleNotificationEmail = (token: string, userId: string,emails:string[]) => {
     return axios.post(`${baseUrl}/send-email/${userId}`,emails, getConfig(token)).then((res) => {
       return res.data;
     });
  };
  public static getFeatureFlags = () => {
     return axios.get(`${baseUrl}/feature-flags`).then((res) => {
       return res.data;
     }).catch(error => console.log(error));
  };


}